import { salesHooks } from '@/modules/Sales/hooks'

import { Feature } from '~generated-types'

import { SectionContainer } from '../../components'
import { SectionFeatures } from '../../types'
import {
  Basic,
  Permissions,
  PublishedProducts,
  PublishedRooms,
} from './components'
import { PublishContextProvider } from './PublishState'

const SECTION: SectionFeatures = {
  icon: 'eye',
  key: 'publish',
}

const PublishComponent = () => {
  const {
    data: {
      facet: { features },
    },
  } = salesHooks.useSalesDetailsContext()

  const hasPublish = features.some(({ feature }) => feature === Feature.Publish)
  const hasPublishRoom = features.some(
    ({ feature }) => feature === Feature.PublishRoom
  )

  const showPublishedRooms = hasPublishRoom
  const showPermissions = !(hasPublish && hasPublishRoom)

  return (
    <PublishContextProvider>
      <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
        <Basic />

        {showPermissions && <Permissions />}

        <PublishedProducts />

        {showPublishedRooms && <PublishedRooms />}
      </SectionContainer>
    </PublishContextProvider>
  )
}

export const Publish = Object.freeze({
  component: PublishComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
