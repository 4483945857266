import type { ApolloClient } from '@apollo/client'
import { gql } from '@apollo/client'
import moment, { Moment } from 'moment'
import writeXlsxFile, { Schema } from 'write-excel-file'

import { generateCompareFn } from '@/utils/arrays'

import {
  ReservationReportExcelQuery as QueryData,
  ReservationReportExcelQueryVariables as QueryVariables,
} from '~generated-types'

const QUERY = gql`
  fragment ReservationReportDimension on DimensionReference {
    id
    selection {
      dimension {
        id
        name
        sortOrder
      }
      selectedLabel {
        id
        name
      }
    }
  }

  query ReservationReportExcel($input: ResourceReservationsInput!) {
    resourceReservations(input: $input) {
      reservations {
        reservations {
          end
          dimensions {
            ...ReservationReportDimension
          }
          internalNote
          resource {
            id
            name
          }
          sales {
            dimensions {
              ...ReservationReportDimension
            }
            id
            orderNumber
            name
            seller {
              shortName
            }
          }
          start
        }
      }
    }
  }
`

type Reservation =
  QueryData['resourceReservations']['reservations'][0]['reservations'][0]

type Input = {
  client: ApolloClient<object>
  dateRange: Moment[]
  dimensionIds: string[]
  resourceName: string
  resourceIds: string[]
  translate: (key: string) => string
}

export const generateReservationReportExcel = async ({
  client,
  dateRange,
  dimensionIds,
  resourceIds,
  resourceName,
  translate,
}: Input): Promise<void> => {
  const sortedDateRange = dateRange.sort((a, b) => a.valueOf() - b.valueOf())

  const { data } = await client.query<QueryData, QueryVariables>({
    fetchPolicy: 'no-cache',
    query: QUERY,
    variables: {
      input: {
        end: sortedDateRange[sortedDateRange.length - 1]
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
        resourceIds: resourceIds,
        start: sortedDateRange[0].startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      },
    },
  })

  if (!data) {
    throw new Error('Failed to fetch reservation excel data')
  }

  const reservations = data.resourceReservations.reservations
    .reduce((acc: Reservation[], r) => [...acc, ...r.reservations], [])
    .sort(
      generateCompareFn([
        'sales.seller.shortName',
        'sales.orderNumber',
        'start',
      ])
    )

  const dimensionLabelsById = reservations.reduce(
    (acc: Record<string, string>, { sales }) => {
      sales.dimensions?.selection
        .filter(({ dimension: { id } }) => dimensionIds.includes(id))
        .sort(generateCompareFn('dimension.sortOrder'))
        .forEach(({ dimension: { id, name } }) => (acc[id] = name))

      return acc
    },
    {}
  )

  const dimensionsSchema: Schema<Reservation> = Object.entries(
    dimensionLabelsById
  ).map(([key, name]) => ({
    column: name,
    value: ({ sales }) =>
      sales.dimensions.selection.find(({ dimension: { id } }) => id === key)
        ?.selectedLabel?.name,
    width: 20,
  }))

  const reservationDimensionLabelsById = reservations.reduce(
    (acc: Record<string, string>, { dimensions }) => {
      dimensions?.selection.forEach(
        ({ dimension: { id, name } }) => (acc[id] = name)
      )

      return acc
    },
    {}
  )

  const reservationDimensionsSchema: Schema<Reservation> = Object.entries(
    reservationDimensionLabelsById
  ).map(([key, name]) => ({
    column: name,
    value: ({ dimensions }) =>
      dimensions?.selection.find((s) => s.dimension.id === key)?.selectedLabel
        ?.name,
    width: 15,
  }))

  const schema: Schema<Reservation> = [
    {
      column: translate('Reports:Reservations.field.resource'),
      value: ({ resource }) =>
        resource?.name ?? translate('ResourceReservations:resource.program'),
      width: 15,
    },
    ...dimensionsSchema,
    {
      column: translate('Reports:Reservations.field.salesNumber'),
      value: ({ sales }) => sales.orderNumber,
      width: 15,
    },
    {
      column: translate('Reports:Reservations.field.salesName'),
      value: ({ sales }) => sales.name,
      width: 25,
    },
    {
      column: translate('Reports:Reservations.field.start'),
      value: ({ start }) =>
        `${moment(start).format('dd DD.MM.YYYY')}, ${moment(start).format(
          'HH:mm'
        )}`,
      width: 20,
    },
    {
      column: translate('Reports:Reservations.field.end'),
      value: ({ end }) =>
        `${moment(end).format('dd DD.MM.YYYY')}, ${moment(end).format(
          'HH:mm'
        )}`,
      width: 20,
    },
    {
      column: translate('Reports:Reservations.field.duration'),
      type: Number,
      value: ({ end, start }) =>
        Number(moment(end).diff(moment(start), 'h', true)),
      width: 10,
    },
    ...reservationDimensionsSchema,
    {
      column: translate('Reports:Reservations.field.internalNote'),
      value: ({ internalNote }) => internalNote,
      width: 30,
      wrap: true,
    },
  ]

  await writeXlsxFile<Reservation>(reservations, {
    fileName: `${translate('Reports:Reservations.fileName')}-${
      resourceName || resourceIds
    }.xlsx`,
    schema,
  })
}
