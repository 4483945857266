import React from 'react'

import { Input } from '@/components/FormControls'

import TimeInputControl, { TimeValue } from './TimeInputControl'

type TimeInputProps = {
  setValue: (value: TimeValue | null | undefined) => void
  value: TimeValue | null | undefined
}

const TimeInput = ({ setValue, value, ...inputProps }: TimeInputProps) => (
  <TimeInputControl setValue={setValue} value={value}>
    {({ handleSubmit, inputValue, setInputValue }) => (
      <Input
        {...inputProps}
        onBlur={handleSubmit}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setInputValue(event.target.value)
        }
        value={inputValue}
      />
    )}
  </TimeInputControl>
)

export default TimeInput
