import styled, { css } from 'styled-components/macro'

import Collapse from '@/components/Collapse'
import { FlexColumn } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T, useLanguageContext } from '@/modules/Language'

import {
  AccommodationLevelAvailability,
  AvailabilityTableProps,
} from '../../AvailabilityCalendar.types'
import { CategorySection } from './CategorySection'
import { CountStatusRow } from './CountStatusRow'
import {
  PrimaryDataRow,
  PrimaryHeaderRow,
  ScrollTableProps,
} from './ScrollTable'

type Props = AvailabilityTableProps &
  ScrollTableProps & {
    data: AccommodationLevelAvailability
    handleOnSelectRoom?: (arg0: {
      roomId: string
      roomNumber: string
      roomTypeName: string
    }) => void
    handleOnSelectRoomType?: (arg0: {
      roomTypeId: string
      roomTypeName: string
    }) => void
    isFirst: boolean
    isLast: boolean
  }

export const AccommodationLevelSection = ({
  data,
  emphasis,
  handleOnSelectRoom,
  handleOnSelectRoomType,
  isFirst,
  isLast,
  mode,
  openSections,
  toggleSectionOpen,
  sectionOpen,
  sectionClose,
  ...tableProps
}: Props) => {
  const { language } = useLanguageContext()

  const sectionId = `accommodationLevel-${data.accommodationLevel.id}`
  const isExpanded = openSections[sectionId]

  const categoriesIds = data.categories.map(({ category }) => category.id)
  const roomTypesIds = data.categories
    .map(({ roomTypes }) => roomTypes.map(({ roomType }) => roomType.id))
    .flat()

  const isRoomTypeExpanded =
    openSections[sectionId] &&
    categoriesIds.filter((id) => openSections[`category-${id}`]).length ===
      categoriesIds.length

  const isRoomExpanded =
    isRoomTypeExpanded &&
    roomTypesIds.filter((id) => openSections[`roomType-${id}`]).length ===
      roomTypesIds.length

  const handleExpandRoomType = () => {
    if (!sectionClose || !sectionOpen) return

    if (isRoomTypeExpanded) {
      sectionClose(sectionId)
      categoriesIds.forEach((id) => sectionClose(`category-${id}`))
      roomTypesIds.forEach((id) => sectionClose(`roomType-${id}`))
    } else {
      sectionOpen(sectionId)
      categoriesIds.forEach((id) => sectionOpen(`category-${id}`))
      roomTypesIds.forEach((id) => sectionClose(`roomType-${id}`))
    }
  }

  const handleExpandRoom = () => {
    if (!sectionClose || !sectionOpen) return

    if (isRoomExpanded) {
      sectionClose(sectionId)
      categoriesIds.forEach((id) => sectionClose(`category-${id}`))
      roomTypesIds.forEach((id) => sectionClose(`roomType-${id}`))
    } else {
      sectionOpen(sectionId)
      categoriesIds.forEach((id) => sectionOpen(`category-${id}`))
      roomTypesIds.forEach((id) => sectionOpen(`roomType-${id}`))
    }
  }

  return (
    <Wrapper isLast={isLast}>
      <PrimaryHeaderRow
        isExpanded={isExpanded}
        isFirstGroup={isFirst}
        onToggleExpand={() => toggleSectionOpen(sectionId)}
        onToggleExpandRoom={handleExpandRoom}
        onToggleExpandRoomType={handleExpandRoomType}
        {...tableProps}
      >
        {data.accommodationLevel.name}
      </PrimaryHeaderRow>

      <PrimaryDataRow
        isExpanded={isExpanded}
        renderContent={() => (
          <CountStatusRow
            depth={0}
            mode={mode}
            property="rooms"
            status={data.status}
            {...tableProps}
          />
        )}
        renderHeader={() => (
          <PrimaryHeader bold>
            <T>Accommodation:AvailabilityCalendar.levelHeaders.rooms</T>
          </PrimaryHeader>
        )}
        {...tableProps}
      />

      <PrimaryDataRow
        isExpanded={isExpanded}
        renderContent={() => (
          <CountStatusRow
            depth={0}
            mode={mode}
            property="beds"
            status={data.status}
            {...tableProps}
          />
        )}
        renderHeader={() => (
          <T>Accommodation:AvailabilityCalendar.levelHeaders.beds</T>
        )}
        {...tableProps}
      />

      <PrimaryDataRow
        isExpanded={isExpanded}
        isLastRow
        renderContent={() => (
          <CountStatusRow
            depth={0}
            mode={mode}
            property="extraBeds"
            status={data.status}
            {...tableProps}
          />
        )}
        renderHeader={() => (
          <T>Accommodation:AvailabilityCalendar.levelHeaders.extraBeds</T>
        )}
        {...tableProps}
      />

      <Collapse in={isExpanded}>
        {data.categories
          .sort((a, b) =>
            a.category.name.localeCompare(b.category.name, language, {
              numeric: true,
            })
          )
          .map((x, idx) => (
            <CategorySection
              data={x}
              emphasis={emphasis}
              handleOnSelectRoom={handleOnSelectRoom}
              handleOnSelectRoomType={handleOnSelectRoomType}
              isLastRow={idx + 1 === data.categories.length}
              key={`category-section-${x.category.id}`}
              mode={mode}
              openSections={openSections}
              toggleSectionOpen={toggleSectionOpen}
              {...tableProps}
            />
          ))}
      </Collapse>
    </Wrapper>
  )
}

///////

const PrimaryHeader = styled(FontWeight)`
  ${({ theme }) => css`
    color: ${theme.palette.coal.dark};
  `}
`

const Wrapper = styled(FlexColumn)<{ isLast: boolean }>`
  ${({ isLast, theme }) =>
    isLast ? `border-bottom: 1px solid ${theme.palette.smoke.dark};` : ''}
`
