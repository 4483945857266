import { useMemo, useState } from 'react'
import isEmpty from 'lodash.isempty'
import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import {
  CountLabel,
  ExpansionButton,
  Padding,
  TreeEndCap,
  TreeNodeButton,
  TreePadding,
  TreeRow,
} from '@/components/TreeSelector'
import { T } from '@/modules/Language'
import { CategorySet } from '@/modules/Registry/Category'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { CategoryMap } from '../types'
import { CategoryNode } from './CategoryNode'

type Props = {
  data: CategorySet
  documentCounts: {
    [path: string]: number
  }
  emphasisedPaths: string[]
  expand?: boolean
  hideEmpty?: boolean
  selection: CategoryMap
  toggleCategory: (id: string) => void
}

export const CategorySetTree = ({
  data,
  documentCounts,
  emphasisedPaths,
  expand,
  hideEmpty,
  selection,
  toggleCategory,
}: Props) => {
  const theme = useTheme()

  const [isExpanded, setIsExpanded] = useState<boolean>(!!expand)

  const allCategories = useMemo(
    () => [...data.rootCategories].sort(generateCompareFn('name')),
    [data.rootCategories]
  )
  const filteredCategories =
    hideEmpty && !isEmpty(documentCounts)
      ? data.rootCategories.filter(({ id }) => !!documentCounts[`/${id}`])
      : data.rootCategories
  const emphasisedCategories = filteredCategories.filter(
    ({ id, isEmphasised }) => isEmphasised || emphasisedPaths.includes(`/${id}`)
  )

  const hasEmphasised = !!emphasisedCategories.length
  const hasNonEmphasised =
    emphasisedCategories.length !== filteredCategories.length

  const [showAll, setShowAll] = useState<boolean>(
    !hasNonEmphasised || !hasEmphasised
  )

  const selectionCount = Object.keys(selection).filter(
    (id) => data.id == selection[id].setId
  ).length

  const totalCount = allCategories
    .map(({ id }) => documentCounts[`/${id}`])
    .filter(Boolean)
    .reduce((acc, val) => acc + val, 0)

  const displayCategories = showAll ? filteredCategories : emphasisedCategories

  return (
    <>
      <TreeRow>
        <ExpansionButton
          isExpanded={isExpanded}
          onClick={() => setIsExpanded((current) => !current)}
        >
          <TreeRowLabel>{data.name}</TreeRowLabel>
          {!!selectionCount && (
            <Chip
              color="primary"
              size="xs"
              style={{ marginLeft: `${theme.spacing.gu(1)}rem` }}
            >
              {selectionCount}
            </Chip>
          )}
          <Spacer />
          <CountLabel>{totalCount}</CountLabel>
        </ExpansionButton>
      </TreeRow>
      {isExpanded && (
        <>
          {displayCategories.map((category, idx) => {
            const isLastRow = idx === displayCategories.length - 1

            return (
              <CategoryNode
                category={category}
                depth={1}
                documentCounts={documentCounts}
                emphasisedPaths={emphasisedPaths}
                hideEmpty={hideEmpty}
                isLastReactNode={isLastRow && showAll}
                key={`category-node-${category.id}`}
                pathPrefix=""
                padding={[]}
                selection={selection}
                toggleCategory={toggleCategory}
              />
            )
          })}
          {!showAll && (
            <TreeRow>
              {['half'].map((mode, idx: number) => (
                <TreePadding
                  key={`tree-padding-root-${idx}-more`}
                  mode={mode as Padding}
                />
              ))}
              <TreeEndCap />
              <TreeNodeButton onClick={() => setShowAll(true)}>
                <T>Reactivesearch:showAll</T>
              </TreeNodeButton>
            </TreeRow>
          )}
        </>
      )}
    </>
  )
}

////////////

const Spacer = styled.div`
  flex: 1;
`

const TreeRowLabel = styled.span`
  line-height: 1;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
