import Collapse from '@/components/Collapse'
import { useLanguageContext } from '@/modules/Language'

import {
  AvailabilityTableProps,
  CategoryAvailability,
} from '../../AvailabilityCalendar.types'
import { CountStatusRow } from './CountStatusRow'
import { RoomTypeSection } from './RoomTypeSection'
import { IndentedDataRow, ScrollTableProps } from './ScrollTable'

type Props = AvailabilityTableProps &
  ScrollTableProps & {
    data: CategoryAvailability
    handleOnSelectRoom?: (arg0: {
      roomId: string
      roomNumber: string
      roomTypeName: string
    }) => void
    handleOnSelectRoomType?: (arg0: {
      roomTypeId: string
      roomTypeName: string
    }) => void
    isLastRow: boolean
  }

export const CategorySection = ({
  data,
  emphasis,
  handleOnSelectRoom,
  handleOnSelectRoomType,
  isLastRow,
  mode,
  openSections,
  toggleSectionOpen,
  ...tableProps
}: Props) => {
  const { language } = useLanguageContext()

  const sectionId = `category-${data.category.id}`
  const isExpanded = openSections[sectionId]

  return (
    <>
      <IndentedDataRow
        indents={[true]}
        isExpanded={isExpanded}
        isLastRow={isLastRow}
        onToggleExpand={() => toggleSectionOpen(sectionId)}
        renderContent={() => (
          <CountStatusRow
            categoryName={data.category.name}
            depth={1}
            mode={mode}
            property="rooms"
            roomTypes={data.roomTypes.map(({ roomType }) => ({
              id: roomType.id,
              name: roomType.name,
            }))}
            status={data.status}
            {...tableProps}
          />
        )}
        renderHeader={() => data.category.name}
        {...tableProps}
      />

      <Collapse in={isExpanded}>
        {data.roomTypes
          .sort((a, b) =>
            a.roomType.name.localeCompare(b.roomType.name, language, {
              numeric: true,
            })
          )
          .map((x, idx) => (
            <RoomTypeSection
              data={x}
              emphasis={emphasis}
              handleOnSelectRoom={handleOnSelectRoom}
              handleOnSelectRoomType={
                handleOnSelectRoomType
                  ? () =>
                      handleOnSelectRoomType({
                        roomTypeId: x.roomType.id,
                        roomTypeName: x.roomType.name,
                      })
                  : undefined
              }
              isLastCategory={isLastRow}
              isLastRow={idx + 1 === data.roomTypes.length}
              key={`room-type-section-${x.roomType.id}`}
              mode={mode}
              openSections={openSections}
              toggleSectionOpen={toggleSectionOpen}
              {...tableProps}
            />
          ))}
      </Collapse>
    </>
  )
}
