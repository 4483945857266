import { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'
import { H4 } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { CalendarCompactResourceElastic } from '@/modules/Registry/CalendarResources'
import { useRouteDateInterval, useRouteValue } from '@/utils/hooks'

import { DateRangePicker } from './DateRangePicker'
import { DimensionSelector } from './DimensionSelector'
import { generateReservationReportExcel } from './generateReservationReportExcel'
import { ResourceSelector } from './ResourceSelector'

export const Reservations = () => {
  const client = useApolloClient()

  const { range: dateRange } = useRouteDateInterval()
  const { value: targetDimensionIds } = useRouteValue({
    multi: true,
    routeKey: 'dimension',
  })
  const { language } = useLanguageContext()

  const [isProcessing, setProcessing] = useState<boolean>(false)
  const [targetResources, setTargetResources] = useState<
    CalendarCompactResourceElastic[]
  >([])

  const handleDownloadReport = () => {
    if (!targetResources.length) {
      return
    }

    setProcessing(true)

    return generateReservationReportExcel({
      client,
      dateRange,
      dimensionIds: Array.isArray(targetDimensionIds) ? targetDimensionIds : [],
      resourceIds: targetResources.flatMap((targetResource) => [
        targetResource.id,
        ...(targetResource?.nestedResources?.map(({ id }) => id) ?? []),
      ]),
      resourceName: (() => {
        const limit = 5
        const selected = targetResources.slice(0, limit).map((res) => res.name)
        const remainingCount = targetResources.length - limit

        return remainingCount > 0
          ? `${selected.join('-')}+${remainingCount}${translate('Common:helpers.more', language)}`
          : selected.join('-')
      })(),
      translate: (key: string) => translate(key, language),
    })
      .catch(() => undefined)
      .finally(() => setProcessing(false))
  }

  return (
    <ContentWrapper>
      <Controls>
        <Title>
          <T>Reports:Reservations.title</T>
        </Title>

        <DateRangePicker />

        <ResourceSelector setTargetResources={setTargetResources} />

        <DimensionSelector />

        <TriggerContainer>
          <Button
            disabled={!targetResources.length || isProcessing}
            onClick={handleDownloadReport}
            size="big"
          >
            <Icon icon={['far', 'file-excel']} />{' '}
            <T>Reports:Reservations.download</T>
          </Button>
        </TriggerContainer>
      </Controls>
    </ContentWrapper>
  )
}

////////////

const ContentWrapper = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutterBig};
  `}
`

const Controls = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: solid 1px ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gutter} ${theme.spacing.gutterBig};
    width: ${theme.spacing.gu(50)}rem;
  `}
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled(H4)`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBigger};
    margin-bottom: ${theme.spacing.gu(2)}rem;
    padding-bottom: ${theme.spacing.gu(2)}rem;
    border-bottom: solid 1px ${theme.palette.smoke.dark};
  `}
`

const TriggerContainer = styled.div`
  margin: auto;
  text-align: center;
  width: 100%;

  ${({ theme }) => css`
    border-top: solid 1px ${theme.palette.smoke.dark};
    margin-top: ${theme.spacing.gu(1)}rem;
    padding-top: ${theme.spacing.gu(2)}rem;
  `}
`
