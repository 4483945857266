import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { CategorySet } from '@/modules/Registry/Category'

import { CategoryMap } from '../types'
import { groupNamesBySet } from '../utils'

type Props = {
  selection: CategoryMap
  categorySets: CategorySet[]
  placeholder: ReactNode
}

export const CollapsedCategorySelectorValue = ({
  selection,
  categorySets,
  placeholder,
}: Props) => {
  const value = groupNamesBySet(selection, categorySets)

  return (
    <Wrapper>
      {Object.keys(value)
        .sort()
        .map((set) => (
          <Row key={`collapsed-set-${set}`}>
            <Label>{set}</Label>
            {value[set] ? (
              <Value>{value[set].join(', ')}</Value>
            ) : (
              <ValuePlaceholder>{placeholder}</ValuePlaceholder>
            )}
          </Row>
        ))}
    </Wrapper>
  )
}

////////////

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
    background-color: ${theme.palette.smoke.lighter};
    border: 1px solid ${theme.palette.smoke.main};
  `}
`

const Row = styled.div`
  display: inline-flex;
  align-items: baseline;
`

const Label = styled.span`
  flex: 0 0 25%;
  white-space: pre-wrap;
  text-align: right;
  font-variant-caps: all-small-caps;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-right: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Value = styled.span`
  font-weight: 400;
  flex: 1;
  white-space: pre-wrap;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const ValuePlaceholder = styled(Value)`
  font-style: italic;
  text-align: center;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
