import { useState } from 'react'
import isEmpty from 'lodash.isempty'
import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import {
  CountLabel,
  ExpansionButton,
  Padding,
  TreeEndCap,
  TreeNodeButton,
  TreePadding,
  TreeRow,
} from '@/components/TreeSelector'
import { T } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'

import { CategoryMap, CategoryWithChildren } from '../types'

type Props = {
  category: CategoryWithChildren
  depth: number
  documentCounts: {
    [path: string]: number
  }
  emphasisedPaths: string[]
  hideEmpty?: boolean
  isLastReactNode: boolean
  padding: Padding[]
  pathPrefix: string
  selection: CategoryMap
  toggleCategory: (id: string) => void
}

export const CategoryNode = ({
  category,
  depth,
  documentCounts,
  emphasisedPaths,
  hideEmpty,
  isLastReactNode,
  pathPrefix,
  padding,
  selection,
  toggleCategory,
}: Props) => {
  const path = `${pathPrefix}/${category.id}`
  const parentIds = pathPrefix.split('/').filter(Boolean)

  const allChildren =
    [...(category?.children || [])].sort(generateCompareFn('name')) || []
  const filteredChildren =
    hideEmpty && !isEmpty(documentCounts)
      ? allChildren.filter(({ id }) => !!documentCounts[`${path}/${id}`])
      : allChildren
  const emphasisedChildren = filteredChildren.filter(
    ({ id, isEmphasised }) =>
      isEmphasised || emphasisedPaths.includes(`${path}/${id}`)
  )

  const hasEmphasised = emphasisedPaths.includes(path)
  const hasNonEmphasised = emphasisedChildren.length !== filteredChildren.length
  const isLeaf = !filteredChildren.length

  const [isExpanded, setIsExpanded] = useState<boolean>(
    emphasisedPaths.includes(path)
  )
  const [showAll, setShowAll] = useState<boolean>(
    !hasNonEmphasised || !hasEmphasised
  )

  const count = documentCounts[path] ?? 0
  const isIndeterminate = Object.keys(selection)
    .map((id) => selection[id].searchPath)
    .some(
      (searchPath) =>
        searchPath.startsWith(path) && searchPath.length > path.length
    )
  const isParentSelected = parentIds.some((id) => !!selection[id])
  const isSelected = !!selection[category.id] || false

  const displayChildren = showAll ? filteredChildren : emphasisedChildren

  return (
    <>
      <TreeRow>
        {[...padding, isLastReactNode ? 'half' : 'full'].map((mode, idx) => (
          <TreePadding
            key={`tree-padding-${category.id}-${idx}`}
            mode={mode as Padding}
          />
        ))}
        {!isLeaf ? (
          <ExpansionButton
            isExpanded={isExpanded}
            onClick={() => setIsExpanded((current) => !current)}
          />
        ) : (
          <TreeEndCap />
        )}
        <CheckboxWrapper flex={1}>
          <CheckboxInput
            checked={isSelected || isParentSelected}
            indeterminate={isIndeterminate}
            noMargin
            onChange={() => toggleCategory(category.id)}
          >
            <TreeRowLabel>{category.name}</TreeRowLabel>
            <Spacer />
            <CountLabel>{count}</CountLabel>
          </CheckboxInput>
        </CheckboxWrapper>
      </TreeRow>
      {isExpanded && (
        <>
          {displayChildren.map((x, idx) => {
            const isLastRow = idx === displayChildren.length - 1

            return (
              <CategoryNode
                category={x}
                depth={depth + 1}
                documentCounts={documentCounts}
                emphasisedPaths={emphasisedPaths}
                hideEmpty={hideEmpty}
                isLastReactNode={isLastRow && showAll}
                key={`category-node-${x.id}`}
                pathPrefix={path}
                padding={[
                  ...padding,
                  isLastReactNode && showAll ? 'none' : 'vertical',
                ]}
                selection={selection}
                toggleCategory={toggleCategory}
              />
            )
          })}
          {!showAll && (
            <TreeRow>
              {[...padding, isLastReactNode ? 'none' : 'vertical', 'half'].map(
                (mode, idx) => (
                  <TreePadding
                    key={`tree-padding-${category.id}-${idx}-more`}
                    mode={mode as Padding}
                  />
                )
              )}
              <TreeEndCap />
              <TreeNodeButton onClick={() => setShowAll(true)}>
                <T>Reactivesearch:showAll</T>
              </TreeNodeButton>
            </TreeRow>
          )}
        </>
      )}
    </>
  )
}

/////////

const CheckboxWrapper = styled(FlexRow)`
  label {
    flex: 1;

    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}

    &:hover {
      ${({ theme }) => css`
        color: ${theme.palette.primary.darker};
      `}
    }
  }
`

const Spacer = styled.div`
  flex: 1;
`

const TreeRowLabel = styled.span`
  line-height: 1.1;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
    max-width: ${theme.spacing.gu(18)}rem;
  `}
`
