import { CategorySet } from '@/modules/Registry/Category'

import {
  CategoryMap,
  CategoryTreeMap,
  CategoryWithChildren,
  FlatCategorySet,
} from '../types'

export const flattenCategorySets = (categorySets: CategorySet[]) => {
  const categoryMap: CategoryMap = {}
  const categoryTreeMap: CategoryTreeMap = {}

  categorySets.forEach((set) => {
    const flatSet: FlatCategorySet = {
      id: set.id,
      name: set.name,
      rootCategoryIds: [],
    }

    // Process root categories
    set.rootCategories.forEach((rootCategory) => {
      flatSet.rootCategoryIds.push(rootCategory.id)

      // Add root category to maps
      categoryMap[rootCategory.id] = {
        depth: 0,
        id: rootCategory.id,
        isEmphasised: rootCategory.isEmphasised,
        name: rootCategory.name,
        parentId: null,
        searchPath: `/${rootCategory.id}`,
        setId: set.id,
      }

      // Process children recursively
      const processChildren = (
        category: CategoryWithChildren,
        parentId: string,
        parentPath: string,
        depth: number
      ) => {
        if (!category.children) return

        // Initialize children array for this parent
        categoryTreeMap[parentId] = category.children.map((child) => child.id)

        // Process each child
        category.children.forEach((child) => {
          const childPath = `${parentPath}/${child.id}`

          // Add child to category map
          categoryMap[child.id] = {
            depth: depth,
            id: child.id,
            isEmphasised: child.isEmphasised,
            name: child.name,
            parentId: parentId,
            searchPath: childPath,
            setId: set.id,
          }

          // Process this child's children
          if (child.children && child.children.length > 0) {
            processChildren(child, child.id, childPath, depth + 1)
          }
        })
      }

      // Start processing children of root categories
      if (rootCategory.children && rootCategory.children.length > 0) {
        processChildren(rootCategory, rootCategory.id, `/${rootCategory.id}`, 1)
      }
    })
  })

  return { categoryMap, categoryTreeMap }
}
