import { useQuery } from '@apollo/client'

import {
  WebshopAvailableScopesQuery as QueryData,
  WebshopAvailableScopesQueryVariables as QueryVariables,
} from '~generated-types'

import { publishQueries } from '../queries'

type Params = {
  salesId: string
}

export const useAvailableScopes = ({ salesId }: Params) => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(
    publishQueries.webshopAvailableScopes,
    {
      variables: {
        input: {
          salesId,
        },
      },
    }
  )

  const saleAvailableScopes = data?.webshop.availableScopes.scopes ?? []

  return {
    error,
    loading,
    saleAvailableScopes,
  }
}
