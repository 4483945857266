import { ReactElement, useState } from 'react'
import styled, { css } from 'styled-components'

import { CheckboxInput, Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { TreeRow, TreeRowLabel } from '@/components/TreeSelector'
import { translate, useLanguageContext } from '@/modules/Language'

import { CommunicationSettingsFragment, GuestSource } from '~generated-types'

import { useGuestCommunicationContext } from '../GuestCommunicationState'

type Props = {
  label: ReactElement
  readOnly: boolean
  sources: GuestSource[]
  communicationSettings: CommunicationSettingsFragment | null
  salesId: string
}

export const SourceSelect = ({
  label,
  readOnly,
  sources,
  communicationSettings,
  salesId,
}: Props) => {
  const { language } = useLanguageContext()
  const { setGuestCommunication } = useGuestCommunicationContext()
  const [isLoading, setIsLoading] = useState(false)

  const handleSetGuestCommunicationSource = (source: GuestSource) => {
    if (!communicationSettings) return

    const sources = new Set(communicationSettings.sources)
    sources.has(source) ? sources.delete(source) : sources.add(source)

    setIsLoading(true)
    setGuestCommunication({
      recipients: Array.from(sources),
      salesId,
    }).finally(() => setIsLoading(false))
  }

  const isDisabled =
    readOnly || isLoading || !communicationSettings?.template?.id

  return (
    <Wrapper flex={1}>
      <Label>{label}</Label>
      <FlexRow>
        <SelectorWrapper>
          {sources.map((source) => (
            <CheckboxWrapper key={`sale-type-option-${source}`}>
              <CheckboxInput
                disabled={isDisabled}
                checked={communicationSettings?.sources.includes(source)}
                noMargin
                onChange={() => handleSetGuestCommunicationSource(source)}
              >
                <TreeRowLabel>
                  {translate(
                    `GuestCommunication:source.options.${source}`,
                    language
                  )}
                </TreeRowLabel>
              </CheckboxInput>
            </CheckboxWrapper>
          ))}
        </SelectorWrapper>
      </FlexRow>
    </Wrapper>
  )
}

/////////

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(2)}rem;
  `}
`

const CheckboxWrapper = styled(TreeRow)`
  &:not(:last-child) {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gu(1)}rem;
    `}
  }
`

const SelectorWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem;
  `}
`
