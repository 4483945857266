import styled, { css } from 'styled-components'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'

import { GuestSource, ResourceOrigin, SalesType } from '~generated-types'

import { ContentSection } from '../../Sales/components/SalesDetails/components'
import {
  HorizontalDivider,
  ListContainerColumn,
  RowWrapper,
  VerticalDivider,
} from '../common'
import { SourceSelect, SyncInfo, TemplateSelect } from './components'
import { useGuestCommunicationContext } from './GuestCommunicationState'

export const GuestCommunication = () => {
  const {
    data: { communicationSettings, id, type },
    saleReadOnly,
  } = salesHooks.useSalesDetailsContext()

  const { setGuestCommunication } = useGuestCommunicationContext()

  const handleResetTemplate = () => {
    setGuestCommunication({
      resetDefault: true,
      salesId: id,
    })
  }

  const readOnly = saleReadOnly || type === SalesType.Enrollment
  const isSale = type === SalesType.Sales
  const isEvent = type === SalesType.Event
  const isEnrollment = type === SalesType.Enrollment
  const noTemplate = !communicationSettings?.template?.id
  const description = communicationSettings?.template?.description
  const isManualOrigin = communicationSettings?.origin === ResourceOrigin.Manual

  return (
    <RowWrapper>
      <ContentSection
        titleAlign="center"
        title={
          <TitleWrapper>
            <T>GuestCommunication:title</T>
            {isEnrollment && (
              <ManageStatus>
                <T>GuestCommunication:managed</T>
              </ManageStatus>
            )}
          </TitleWrapper>
        }
        headerExtra={
          isManualOrigin && (
            <InnocuousButton
              compact
              disabled={readOnly}
              onClick={handleResetTemplate}
            >
              <PrimaryColor>
                <T>GuestCommunication:template.reset</T>
              </PrimaryColor>
            </InnocuousButton>
          )
        }
      >
        <ListContainerColumn>
          <FlexRow>
            <TemplateSelect
              communicationSettings={communicationSettings}
              salesId={id}
              readOnly={readOnly}
            />
            <VerticalDivider />

            <SourceSelect
              label={
                isSale ? (
                  <T>GuestCommunication:source.title</T>
                ) : (
                  <T>GuestCommunication:source.enrollmentTitle</T>
                )
              }
              sources={[GuestSource.Customer, GuestSource.Participant]}
              communicationSettings={communicationSettings}
              readOnly={readOnly}
              salesId={id}
            />

            <VerticalDivider />

            {isEvent && (
              <>
                <SourceSelect
                  label={<T>GuestCommunication:source.eventTitle</T>}
                  sources={[GuestSource.EventCustomer]}
                  communicationSettings={communicationSettings}
                  readOnly={readOnly}
                  salesId={id}
                />

                <VerticalDivider />
              </>
            )}

            <SyncInfo
              integrationStatus={communicationSettings?.integrationStatus}
            />
          </FlexRow>

          {(noTemplate || description) && (
            <>
              <HorizontalDivider />

              <Description>
                {description ?? <T>GuestCommunication:source.noTemplate</T>}
              </Description>
            </>
          )}
        </ListContainerColumn>
      </ContentSection>
    </RowWrapper>
  )
}

////////////

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ManageStatus = styled.span`
  font-variant: none;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    color: ${theme.palette.text.light};
    background-color: ${theme.palette.smoke.light};
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(2)}rem;
    margin-left: ${theme.spacing.gu(2)}rem;
    border-radius: ${theme.spacing.gu(2)}rem;
  `}
`

const Description = styled(FlexRow)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1.5)}rem 0rem ${theme.spacing.gu(1)}rem;
  `}
`
