import isNumber from 'lodash.isnumber'
import moment from 'moment'
import styled, { css } from 'styled-components'

import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'

import {
  CommunicationSettingsFragment,
  IntegrationSyncStatus,
} from '~generated-types'

import { ContentPlaceholder } from '../../common'

type Props = {
  integrationStatus?: CommunicationSettingsFragment['integrationStatus']
}
export const SyncInfo = ({ integrationStatus }: Props) => {
  const { language } = useLanguageContext()

  const hasDate = integrationStatus?.lastIntegrationSyncAt
    ? moment(integrationStatus.lastIntegrationSyncAt).format('DD.MM.YYYY HH:mm')
    : ''

  const hasErrorStatus =
    integrationStatus?.status === IntegrationSyncStatus.Error
      ? translate(
          `GuestCommunication:lastSync.status.${integrationStatus.status}`,
          language
        )
      : ''

  const hasRows =
    integrationStatus?.totalRows != null &&
    isNumber(integrationStatus.totalRows)
      ? integrationStatus.totalRows
      : null

  const syncInfo = [hasDate, hasErrorStatus].filter(Boolean).join(' - ')

  return (
    <Wrapper flex={1}>
      <Label>
        <T>GuestCommunication:lastSync.title</T>
      </Label>
      <InnerWrapper>
        <FlexColumn>
          {syncInfo || hasRows ? (
            <>
              {syncInfo && <span>{syncInfo}</span>}
              {hasRows !== null && (
                <span>
                  {hasRows}{' '}
                  <T>
                    {hasRows === 1
                      ? 'GuestCommunication:lastSync.syncedGuest'
                      : 'GuestCommunication:lastSync.syncedGuests'}
                  </T>
                </span>
              )}
            </>
          ) : (
            <ContentPlaceholder>
              - <T>GuestCommunication:lastSync.notSynced</T> -
            </ContentPlaceholder>
          )}
        </FlexColumn>
      </InnerWrapper>
    </Wrapper>
  )
}

/////////

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(2)}rem;
  `}
`

const InnerWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem 0;
  `}
`
