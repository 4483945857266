import { CategorySet } from '@/modules/Registry/Category'

import { CategoryMap } from '../types'

export const groupNamesBySet = (
  selection: CategoryMap,
  categorySets: CategorySet[]
) =>
  Object.values(selection).reduce(
    (acc: { [setName: string]: string[] }, selection) => {
      const setName = categorySets.find(
        ({ id }) => id === selection.setId
      )?.name
      const formattedName = selection.name.split(';').join(',')
      return setName
        ? { ...acc, [setName]: [...(acc[setName] || []), formattedName] }
        : acc
    },
    {}
  )
