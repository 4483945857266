import { useEffect, useState } from 'react'

import { ById } from '@/common/types'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { ThemedMultiSelect } from '@/components/ThemedSelect'
import { T, translate, useLanguageContext } from '@/modules/Language'
import {
  CalendarCompactResourceElastic,
  CalendarResourcesService,
} from '@/modules/Registry/CalendarResources'
import { generateCompareFn } from '@/utils/arrays'
import { useRouteValue } from '@/utils/hooks'

type Props = {
  setTargetResources: (resources: CalendarCompactResourceElastic[]) => void
}

export const ResourceSelector = ({ setTargetResources }: Props) => {
  const { language } = useLanguageContext()

  const { setValue: setTargetResourceIds, value: targetResourceIds } =
    useRouteValue({
      multi: true,
      routeKey: 'resource',
    })

  const [resourcesById, setResourcesById] = useState<
    ById<CalendarCompactResourceElastic>
  >({})
  const [isFetching, setFetching] = useState<boolean>(true)

  useEffect(() => {
    CalendarResourcesService.findAll()
      .then((resources) => {
        setResourcesById(
          resources.reduce((acc, resource) => {
            // Process nested resources, ensuring they have empty nestedResources
            const flattenedNestedResources =
              resource.nestedResources?.reduce((nestedAcc, nested) => {
                return {
                  ...nestedAcc,
                  [nested.id]: {
                    ...nested,
                    name: `${resource.name}: ${nested.name}`,
                    nestedResources: [],
                  },
                }
              }, {}) ?? {}

            return {
              ...acc,
              [resource.id]: {
                ...resource,
              },
              // Flatten all nested resources into the top-level object
              ...flattenedNestedResources,
            }
          }, {})
        )
        setTargetResources(
          (typeof targetResourceIds === 'string'
            ? [targetResourceIds]
            : (targetResourceIds ?? [])
          )
            .map((id) => resourcesById[id])
            .filter(Boolean)
        )
      })
      .catch((err) => console.warn('Failed to retrieve all resources', err))
      .finally(() => setFetching(false))
  }, [])

  const selectorOptions = Object.values(resourcesById)
    .sort(generateCompareFn('name'))
    .map(({ id, name }) => ({
      label:
        name || translate('ResourceReservations:unnamedResource', language),
      searchValue: name || '',
      value: id,
    }))

  const selectorValues =
    typeof targetResourceIds === 'string'
      ? [targetResourceIds]
      : (targetResourceIds ?? [])

  const handleSetTargetResources = (resourceIds: string[]) => {
    setTargetResourceIds(resourceIds)
    setTargetResources(resourceIds.map((id) => resourcesById[id]))
  }

  return (
    <FilterSection
      label={<T>ResourceReservationsCalendar:ResourceSelector.title</T>}
      render={() => (
        <ThemedMultiSelect
          loading={isFetching}
          withSearch
          withSelectAll
          setSelectedValues={(values: string[]) => {
            handleSetTargetResources(values)
          }}
          options={selectorOptions}
          placeholder={
            <T>ResourceReservationsCalendar:ResourceSelector.placeholder</T>
          }
          selectedValues={selectorValues}
        />
      )}
    />
  )
}
