import { gql } from '@apollo/client'

export default gql`
  fragment CommunicationSettings on GuestCommunicationSettings {
    id
    integrationStatus {
      lastIntegrationSyncAt
      status
      totalRows
    }
    origin
    rateCode
    sources
    template {
      description
      id
      name
    }
  }
`
