import isEmpty from 'lodash.isempty'

import { CategoryMap } from '../types'

/**
 * Generate an Elasticsearch query from category IDs and a category map
 *
 * @param categoryIds Selected category IDs
 * @param categoryMap Map of category IDs to category objects
 * @param defaultSort Default sort order
 * @returns Elasticsearch query object
 */
export const getQuery = (
  categorySelection: CategoryMap,
  defaultSort?: {
    [key: string]: string
  }
) => {
  const categoryIds = Object.keys(categorySelection)
  if (!categoryIds.length) {
    return { value: null }
  }

  // Get search paths for selected categories
  const searchPaths = categoryIds.map((id) => categorySelection[id].searchPath)

  // Get names for selected categories
  const categoryNames = categoryIds
    .map((id) => categorySelection[id].name)
    .map((name) => name.replace(',', ';'))

  return {
    query: {
      nested: {
        path: 'categories',
        query: {
          terms: {
            'categories.path': searchPaths,
          },
        },
      },
    },
    sort: !isEmpty(defaultSort) ? undefined : defaultSort,
    value: categoryNames,
  }
}
