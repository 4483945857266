import { ReactNode, useEffect, useState } from 'react'
import moment from 'moment'

import { parseTimeFromString } from '@/utils/time'

export type TimeValue = {
  hour: number
  minute: number
}

type TimeInputRenderProps = {
  handleSubmit: () => void
  inputValue: string
  setInputValue: (arg0: string) => void
  value: TimeValue | null | undefined
}

export type TimeInputControlProps = {
  children: (renderProps: TimeInputRenderProps) => ReactNode
  setValue: (value: TimeValue | null | undefined) => void
  value: TimeValue | null | undefined
}

const TimeInputControl = ({
  children,
  setValue,
  value,
}: TimeInputControlProps) => {
  const [inputValue, setInputValue] = useState<string>(
    value ? getTimeDisplayValue(value.hour, value.minute) : ''
  )

  useEffect(() => {
    setInputValue(value ? getTimeDisplayValue(value.hour, value.minute) : '')
  }, [value])

  const handleSubmit = () => {
    const time = parseTimeFromString(inputValue)

    if (!time) {
      setValue(null)
      setInputValue('')
    } else {
      setValue(time)
      setInputValue(getTimeDisplayValue(time.hour, time.minute))
    }
  }

  return (
    <>
      {children({
        handleSubmit,
        inputValue,
        setInputValue,
        value,
      })}
    </>
  )
}

export default TimeInputControl

////////////

function getTimeDisplayValue(hour: number, minute: number): string {
  return moment().hour(hour).minute(minute).format('HH:mm')
}
