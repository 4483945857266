import { useEffect, useState } from 'react'

import { CategorySelector as ElasticCategorySelector } from '@/components/Reactivesearch'
import { CalendarResourcesService } from '@/modules/Registry/CalendarResources'
import { generateCompareFn } from '@/utils/arrays'

type ElasticCategoryInput = {
  categoryId: string
  searchPath: string
}

type Props = {
  initialSelection: string[]
  isCollapsed: boolean
  isRestrictedMode: boolean
  onUpdateCategories: (categoryIds: string[]) => void
  onUpdateResources: (resourceIds: string[]) => void
  selectorId: string
}

const CategorySelector = ({
  initialSelection,
  isCollapsed,
  isRestrictedMode,
  onUpdateCategories,
  onUpdateResources,
  selectorId,
}: Props) => {
  const [selectedCategories, setSelectedCategories] = useState<{
    [setId: string]: ElasticCategoryInput[]
  } | null>(null)

  useEffect(() => {
    if (!selectedCategories) {
      return
    }

    const categoryIds = Object.values(selectedCategories)
      .flat()
      .map((category) => category.categoryId)
    onUpdateCategories(categoryIds)

    if (!categoryIds.length) {
      onUpdateResources([])
    } else {
      CalendarResourcesService.findAllByCategories(
        Object.keys(selectedCategories).map((x) =>
          selectedCategories[x].map((cat) => cat.searchPath)
        ),
        isRestrictedMode
      )
        .then((res) =>
          onUpdateResources(
            res.sort(generateCompareFn('name')).map(({ id }) => id) ?? []
          )
        )
        .catch((err) => {
          console.warn('Failed to resolve calendar resources', err)
          return []
        })
    }
  }, [isRestrictedMode, selectedCategories])

  return (
    <ElasticCategorySelector
      categoryTargetKey="RESOURCE_SEARCH"
      componentId={`category-${selectorId}`}
      defaultQuery={() => ({})}
      hideEmpty
      forcedInitialSelection={initialSelection}
      isCollapsed={isCollapsed}
      onUpdateSelection={(selected) => setSelectedCategories(selected)}
      showFilter={false}
      URLParams={false}
    />
  )
}

export default CategorySelector
