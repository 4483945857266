import { useState } from 'react'
import { Moment } from 'moment'
import styled, { css } from 'styled-components'

import { DateRange } from '@/common/types'
import { PrimaryColor, WarningColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { InlineModal, InlineModalContent } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { FontWeight } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import EmptyLabel from '../../ParticipantRow/common/ServiceItemEmptyLabel'
import ServiceItemSquared from '../../ParticipantRow/common/ServiceItemSquared'
import { VSTDatesModal } from './VSTDatesModal'

type Dates = {
  end: Moment
  start: Moment
}

type Props = {
  participantId: string
  dates: Dates | null
  isVSTError: boolean
  vstDays?: number | null
  handleChange: (dates: DateRange | null, updateAll?: boolean) => Promise<void>
  handleSetDefault: (updateAll?: boolean) => Promise<void>
}

export const VSTDates = ({
  participantId,
  dates: datesProps,
  isVSTError,
  vstDays,
  handleChange,
  handleSetDefault,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { language } = useLanguageContext()

  const [isEditing, setEditing] = useState(false)
  const [isDefault, setDefault] = useState(false)
  const [isTimeError, setTimeError] = useState(false)
  const [isUpdateAll, setUpdateAll] = useState(false)
  const [dates, setDates] = useState<Dates | null>(datesProps)

  const handleModalState = (isOpen: boolean) => {
    setEditing(isOpen)
    setUpdateAll(false)
    isOpen && setDates(datesProps)
  }

  return (
    <ModalContainer
      isOpen={isEditing}
      modal={
        <InlineModal>
          <InlineModalContent>
            <FlexColumn alignItems="flex-start">
              <VSTDatesModal
                dates={dates}
                isDefault={isDefault}
                isTimeError={isTimeError}
                isUpdateAll={isUpdateAll}
                participantId={participantId}
                setDates={setDates}
                setDefault={setDefault}
                setTimeError={setTimeError}
                setUpdateAll={setUpdateAll}
              />

              <HorizontalDivider margin={'0'} />

              <Gutter type={[2]} style={{ width: '100%' }}>
                <FlexRow justifyContent="space-between">
                  <InnocuousButton
                    compact
                    disabled={(!dates && !isDefault) || isTimeError}
                    onClick={() =>
                      isDefault
                        ? handleSetDefault(isUpdateAll).then(() =>
                            handleModalState(false)
                          )
                        : dates &&
                          handleChange(
                            {
                              end: dates.end.format('YYYY-MM-DDTHH:mm:ss'),
                              start: dates.start.format('YYYY-MM-DDTHH:mm:ss'),
                            },
                            isUpdateAll
                          ).then(() => handleModalState(false))
                    }
                  >
                    <PrimaryColor>
                      <T>common:action.apply</T>
                    </PrimaryColor>
                  </InnocuousButton>
                  <FlexColumn alignItems="center">
                    <InnocuousButton
                      compact
                      disabled={!dates}
                      onClick={() => {
                        handleChange(null)
                        setDates(null)
                        setEditing(false)
                      }}
                    >
                      <T>SalesDetails:EstimatedDates.clear</T>
                    </InnocuousButton>
                    <FontWeight style={{ color: palette.smoke.extraDark }}>
                      <T>ParticipantsList:VST.cancelDescription</T>
                    </FontWeight>
                  </FlexColumn>
                  <InnocuousButton
                    compact
                    onClick={() => handleModalState(false)}
                  >
                    <WarningColor>
                      <T>common:action.cancel</T>
                    </WarningColor>
                  </InnocuousButton>
                </FlexRow>
              </Gutter>
            </FlexColumn>
          </InlineModalContent>
        </InlineModal>
      }
      onClose={() => handleModalState(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <ServiceItemSquared
          ref={ref}
          isError={isVSTError}
          minWidth={`${spacing.gu(42)}rem`}
          renderContent={() => (
            <>
              {datesProps ? (
                <FlexRow
                  justifyContent="space-between"
                  style={{ width: '100%' }}
                >
                  {datesProps?.start.format('DD.MM.YY, HH:mm')} –{' '}
                  {datesProps?.end.format('DD.MM.YY, HH:mm')}
                  <VstDaysWrapper>
                    {!!vstDays && (
                      <>
                        <span>
                          {vstDays} <T>enums:quantityUnit.abbrev.DAY</T>
                        </span>
                      </>
                    )}
                  </VstDaysWrapper>
                </FlexRow>
              ) : (
                <EmptyLabel>
                  {translate('ParticipantsList:VST.range', language)}
                </EmptyLabel>
              )}
            </>
          )}
          onClick={() => handleModalState(true)}
        />
      )}
    />
  )
}

const HorizontalDivider = styled.div<{ margin?: string }>`
  width: 100%;

  ${({ theme, margin }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
    margin: ${margin ? margin : `${theme.spacing.gu(1)}rem 0`};
  `}
`

const VstDaysWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 0 0 ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(10)}rem;
  `}

  display: flex;
  align-items: center;
  justify-content: flex-end;
`
