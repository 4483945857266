import { FilterSection } from '@/components/ElasticFilterSearchList'
import { LoadingPlaceholder } from '@/components/Placeholders'
import {
  CategorySelector,
  DataSearch,
  MultiList,
} from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { catalogHooks } from '@/modules/Registry/Catalog'

type Props = {
  componentIds: { [key: string]: string }
  getDefaultQuery: () => Record<string, unknown>
  sellerId?: string
  URLParams?: boolean
}

export const ListFilters = ({
  componentIds,
  getDefaultQuery,
  sellerId,
  URLParams = false,
}: Props) => {
  const { language } = useLanguageContext()
  const { catalogs, loading: loadingCatalogs } = catalogHooks.useCatalogs()

  const targetCatalogs = catalogs
    .filter(
      ({ company }) =>
        sellerId &&
        (company.id === sellerId || company.succeededBy?.id === sellerId)
    )
    .map(({ name }) => name)

  return (
    <>
      <FilterSection
        label={<T>Products:CatalogProductList.filter.search</T>}
        render={() => (
          <DataSearch
            autosuggest={false}
            componentId={componentIds.SEARCH}
            dataField={['name', 'code']}
            defaultQuery={getDefaultQuery}
            filterLabel={translate(
              'Products:CatalogProductList.filter.search',
              language
            )}
            placeholder={translate(
              `Products:CatalogProductList.filter.searchPlaceholder`,
              language
            )}
            queryFormat={'and'}
            react={{
              and: [componentIds.CATALOG],
            }}
            showClear
            showIcon
            URLParams={URLParams}
          />
        )}
      />
      <FilterSection
        canCollapse
        label={<T>Products:CatalogProductList.filter.catalog</T>}
        render={({ isCollapsed }) =>
          loadingCatalogs ? (
            <LoadingPlaceholder size="lg" />
          ) : (
            <MultiList
              componentId={componentIds.CATALOG}
              dataField="catalog.name.keyword"
              defaultValue={targetCatalogs}
              filterLabel={translate(
                'Products:CatalogProductList.field.catalog',
                language
              )}
              isCollapsed={isCollapsed}
              missingLabel={translate(
                'Products:CatalogProductList.filter.catalogMissing',
                language
              )}
              showCheckbox
              showMissing
              showSearch={false}
              URLParams={!!URLParams}
            />
          )
        }
      />
      <FilterSection
        canCollapse
        label={<T>Products:CatalogProductList.filter.categories</T>}
        render={({ isCollapsed }) => (
          <CategorySelector
            categoryTargetKey="PRODUCT_LIST"
            componentId={componentIds.CATEGORY}
            defaultQuery={getDefaultQuery}
            filterLabel={translate(
              'Products:CatalogProductList.field.category',
              language
            )}
            hideEmpty
            isCollapsed={isCollapsed}
            URLParams={!!URLParams}
          />
        )}
      />
    </>
  )
}
