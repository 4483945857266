import { gql, useMutation } from '@apollo/client'

import {
  SetGuestCommunicationSettingsMutation,
  SetGuestCommunicationSettingsMutationVariables,
} from '~generated-types'

import communicationsFragment from '../fragments/communicationSettings'

const MUTATION = gql`
  ${communicationsFragment}
  mutation setGuestCommunicationSettings(
    $input: SalesGuestCommunicationSettingsInput!
  ) {
    salesSetGuestCommunicationSettings(input: $input) {
      ...CommunicationSettings
    }
  }
`

export const useSetGuestCommunicationMutation = () =>
  useMutation<
    SetGuestCommunicationSettingsMutation,
    SetGuestCommunicationSettingsMutationVariables
  >(MUTATION)
