import { CSSProperties, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { EditButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { SalesDetails } from '@/modules/Sales/components/SalesDetails/types'
import { Spacing, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { ContentPlaceholder } from '../../common'
import { useGuestCommunicationContext } from '../GuestCommunicationState'

type Props = {
  salesId: string
  communicationSettings: SalesDetails['communicationSettings']
  readOnly: boolean
}

export const TemplateSelect = ({
  communicationSettings,
  salesId,
  readOnly,
}: Props) => {
  const { spacing } = useTheme()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { loading, communicationTemplates, setGuestCommunication } =
    useGuestCommunicationContext()

  const handleSelectTemplate = (option?: Option | null) => {
    setIsProcessing(true)
    setGuestCommunication({
      communicationTemplateId: option?.value || null,
      salesId,
    }).finally(() => {
      setIsProcessing(false), setEditMode(false)
    })
  }

  const communicationTemplatesOptions =
    communicationTemplates
      ?.map((template) => ({
        label: template.name,
        searchValue: template.name,
        value: template.id,
      }))
      .sort(generateCompareFn('label')) || []

  const templateName = communicationSettings?.template?.name
  const templateId = communicationSettings?.template?.id

  return (
    <Wrapper flex={1} noPadding>
      <Label>
        <T>GuestCommunication:template.title</T>
      </Label>
      <FlexRow>
        {isEditMode ? (
          <ThemedSelect
            autoFocus
            isSearchable
            isClearable
            isDisabled={readOnly}
            extraStyles={getExtraStyles(spacing)}
            isCompact
            isLoading={isProcessing || loading}
            menuIsOpen={isEditMode}
            name="guest-communication-template"
            noOptionsMessage={() => <T>GuestCommunication:template.empty</T>}
            onBlur={() => setEditMode(false)}
            onChange={handleSelectTemplate}
            options={communicationTemplatesOptions}
            placeholder={<T>GuestCommunication:template.placeholder</T>}
            value={
              templateName && templateId
                ? {
                    label: templateName,
                    value: templateId,
                  }
                : null
            }
          />
        ) : (
          <EditButton disabled={readOnly} onClick={() => setEditMode(true)}>
            {templateName || (
              <ContentPlaceholder>
                <T>GuestCommunication:template.placeholder</T>
              </ContentPlaceholder>
            )}
          </EditButton>
        )}
      </FlexRow>
    </Wrapper>
  )
}

/////

const getExtraStyles = (spacing: Spacing) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    flex: 1,
    zIndex: 600,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
    width: `calc(100% + ${spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(2)}rem)`,
    zIndex: 2,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
  }),
})

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`
