import {
  NavExternalViewType as ExternalKey,
  NavInternalViewType as InternalKey,
} from '../types'

export const navViewAccessRoles = {
  // Search access roles
  [InternalKey.SearchSales]: 'willba-office.search-sales:access',
  [InternalKey.SearchEnrollment]: 'willba-office.search-sales:access',
  [InternalKey.SearchInvoice]: 'willba-office.search-invoice:access',
  [InternalKey.SearchTask]: 'willba-office.search-task:access',

  // Front desk access roles
  [InternalKey.FrontDeskSales]: 'willba-office.front-desk-sales:access',
  [InternalKey.FrontDeskRoom]: 'willba-office.front-desk-room:access',

  // Front desk access roles
  [InternalKey.BookingRoomLayout]: 'willba-office.booking-room-layout:access',
  [InternalKey.BookingAccommodationReservation]:
    'willba-office.booking-accommodation-reservation:access',
  [InternalKey.BookingResourceCalendar]:
    'willba-office.booking-resource-calendar:access',

  // Listing access roles
  [InternalKey.ListingGuest]: 'willba-office.listing-guest:access',
  [InternalKey.ListingPacketMeal]: 'willba-office.listing-packet-meal:access',
  [InternalKey.ListingSales]: 'willba-office.listing-sales:access',
  [InternalKey.ListingRoomStatus]: 'willba-office.listing-room-status:access',
  [InternalKey.ListingRoomReservation]:
    'willba-office.listing-room-reservation:access',
  [InternalKey.ListingReservation]: 'willba-office.listing-reservation:access',

  // Report access roles
  [InternalKey.ReportPayment]: 'willba-office.report-payment:access',
  [InternalKey.ReportReservation]: 'willba-office.report-reservation:access',
  [InternalKey.ReportOpenAdvance]: 'willba-office.report-open-advances:access',

  // Registry access roles
  [InternalKey.RegistryCatalog]: 'willba-office.registry-catalog:access',
  [InternalKey.RegistryCustomer]: 'willba-office.registry-customer:access',
  [InternalKey.RegistryEmail]: 'willba-office.registry-email:access',
  [InternalKey.RegistryProduct]: 'willba-office.registry-product:access',
  [InternalKey.RegistryRoomType]: 'willba-office.registry-room-type:access',
  [InternalKey.RegistryRoom]: 'willba-office.registry-room:access',
  [InternalKey.RegistryBuilding]: 'willba-office.registry-building:access',
  [InternalKey.RegistryUser]: 'willba-office.registry-user:access',
  [InternalKey.RegistryStrapi]: 'willba-office.registry-cms:access',

  // Other access roles
  [InternalKey.ExternalDocs]: null,
  [InternalKey.ExternalReleases]: null,

  // External access roles
  [ExternalKey.BookingExternal]: null,
  [ExternalKey.FrontDeskExternal]: null,
  [ExternalKey.ListingExternal]: null,
  [ExternalKey.RegistryExternal]: null,
  [ExternalKey.ReportExternal]: 'willba-office.report-external:access',
  [ExternalKey.SearchExternal]: null,
}
