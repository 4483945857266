import { gql } from '@apollo/client'

export default gql`
  fragment Category on Category {
    id
    isAssignable
    isEmphasised
    name
  }
`
