import { useState } from 'react'
import styled from 'styled-components'

import { EditButton } from '@/components/ExtraButtons'
import { Option, ThemedMultiSelect } from '@/components/ThemedSelect'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import { WebshopPermissionScope as Scope } from '~generated-types'

import { usePublishContext } from '../../../PublishState'
import { WebshopSettingsToken } from '../../../types'
import { ContentPlaceholder, TruncateContent } from '../../common'

type Props = {
  isSalesPaid: boolean
  token: WebshopSettingsToken
}

export const ScopeSelector = ({
  isSalesPaid,
  token: { scopes, token, validTo, validFrom, completedAt },
}: Props) => {
  const { spacing } = useTheme()
  const { language } = useLanguageContext()
  const {
    readOnly: salesReadOnly,
    salesId,
    updateWebshopSettings,
    saleAvailableScopes,
  } = usePublishContext()

  const readOnly = !!(salesReadOnly || completedAt)

  const [isEditMode, setEditMode] = useState<boolean>(false)

  const { saleScopes } = getSaleScopes({ scopes })

  const [selectedScopes, setSelectedScopes] = useState<string[]>(saleScopes)

  // Set the sale scopes
  const handleChangeSaleScopes = (values: string[]) => {
    if (JSON.stringify(saleScopes) === JSON.stringify(selectedScopes)) return

    const { scopeGroup } = getSaleScopes({ scopeGroups: values })

    updateWebshopSettings({
      salesSettings: {
        salesId,
        scopes: scopeGroup as Scope[],
        tokenId: token,
        validFrom,
        validTo,
      },
    })
      .catch(() => setSelectedScopes([]))
      .finally(() => {
        setEditMode(false)
      })
  }

  return isEditMode ? (
    <MultiSelectWrapper>
      <ThemedMultiSelect
        controlStyle={{
          marginLeft: `-${spacing.gu(1)}rem`,
          width: `calc(100% + ${spacing.gu(2)}rem)`,
        }}
        isCompact
        loading={false}
        noOptionsPlaceholder={
          <T>Publish:Permissions.scope.noScopesAvailable</T>
        }
        modalWidth={`${spacing.gu(38)}rem`}
        labelsAmount={3}
        onModalClose={() => setEditMode(false)}
        openModal={isEditMode}
        options={getAvailableOptions(saleAvailableScopes).map((option) =>
          getOptionTranslation(
            option.value,
            language,
            isSalesPaid,
            selectedScopes
          )
        )}
        placeholder={<T>Publish:Permissions.scope.noScopesSelected</T>}
        selectedValues={selectedScopes}
        setSelectedValues={handleChangeSaleScopes}
        liftSetValues={(values) =>
          handleChangeValues({ selectedScopes, setSelectedScopes, values })
        }
      />
    </MultiSelectWrapper>
  ) : (
    <EditButton
      disabled={readOnly}
      onClick={() => setEditMode(true)}
      style={{ flex: 'unset' }}
    >
      <TruncateContent
        content={
          selectedScopes.length ? (
            selectedScopes
              .map((scope) =>
                translate(`Publish:Permissions.scope.type.${scope}`, language)
              )
              .join(', ')
          ) : (
            <ContentPlaceholder>
              <T>Publish:Permissions.scope.noScopesSelected</T>
            </ContentPlaceholder>
          )
        }
      />
    </EditButton>
  )
}

/////////

const scopeToOption = {
  [Scope.UpdateParticipantProducts]: {
    label: 'Participants',
    value: 'Participants',
  },
  [Scope.UpdateParticipantData]: {
    label: 'ParticipantsEdit',
    value: 'ParticipantsEdit',
  },
  [Scope.QueryCustomer]: {
    label: 'Customer',
    value: 'Customer',
  },
  [Scope.Payment]: { label: 'Payment', value: 'Payment' },
  [Scope.QueryRooms]: { label: 'Rooms', value: 'Rooms' },
}

const getAvailableOptions = (saleAvailableScopes: Scope[]) =>
  saleAvailableScopes
    .map((scope) => scopeToOption[scope as keyof typeof scopeToOption])
    .filter(Boolean) as Option[]

const getOptionTranslation = (
  key: string,
  language: string,
  isSalePaid: boolean,
  selectedScopes: string[]
) => ({
  // Disable select options roles.
  // 1. If the sale has an accepted invoice, disable the Payment and Participants scopes.
  // 2. However, do not disable them if the scopes have already been saved.
  // (Keep the Payment and Participants scopes available for removal)
  // 3. Disable participant selection if room reservation is scoped.
  // This is a temporary fix until participant management implementation is available for room reservations.
  isDisabled:
    (isSalePaid &&
      ((key === 'Payment' && !selectedScopes.includes('Payment')) ||
        (key === 'Participants' &&
          !selectedScopes.includes('Participants')))) ||
    (key === 'Participants' && selectedScopes.includes('Rooms')) ||
    (key === 'ParticipantsEdit' && selectedScopes.includes('Rooms')) ||
    key === 'Rooms',
  label: translate(`Publish:Permissions.scope.type.${key}`, language),
  value: key,
})

const getSaleScopes = ({
  scopes,
  scopeGroups,
}: {
  scopes?: WebshopSettingsToken['scopes']
  scopeGroups?: string[]
}) => {
  const saleScopes: string[] = []
  const scopeGroup: string[] = []

  const scopesMap = {
    Customer: [Scope.QueryCustomer, Scope.UpdateCustomer],
    Participants: [
      Scope.QueryParticipants,
      Scope.CreateParticipant,
      Scope.RemoveParticipant,
      Scope.UpdateParticipantData,
      Scope.UpdateParticipantProducts,
    ],
    ParticipantsEdit: [Scope.QueryParticipants, Scope.UpdateParticipantData],
    Payment: [Scope.Payment],
    Rooms: [
      Scope.UpdateRooms,
      Scope.CreateRooms,
      Scope.QueryRooms,
      Scope.RemoveRooms,
    ],
  }

  for (const [key, value] of Object.entries(scopesMap)) {
    if (
      value.every((scope) => scopes?.includes(scope)) &&
      // Do not add ParticipantsEdit if Participants already exists
      !(key === 'ParticipantsEdit' && saleScopes.includes('Participants'))
    ) {
      saleScopes.push(key)
    }
  }

  if (scopeGroups?.includes('Payment')) {
    scopeGroup.push(...scopesMap['Payment'])
  }
  if (scopeGroups?.includes('Customer')) {
    scopeGroup.push(...scopesMap['Customer'])
  }
  if (scopeGroups?.includes('Participants')) {
    scopeGroup.push(...scopesMap['Participants'])
  }
  if (scopeGroups?.includes('ParticipantsEdit')) {
    scopeGroup.push(...scopesMap['ParticipantsEdit'])
  }
  if (scopeGroups?.includes('Rooms')) {
    scopeGroup.push(...scopesMap['Rooms'])
  }

  return { saleScopes, scopeGroup }
}

const handleChangeValues = ({
  values,
  selectedScopes,
  setSelectedScopes,
}: {
  values: string[]
  selectedScopes: string[]
  setSelectedScopes: (values: string[]) => void
}) => {
  // Modify values to ensure exclusivity between 'Participants' and 'ParticipantsEdit'.
  if (
    values.length &&
    values.includes('Participants') &&
    values.includes('ParticipantsEdit')
  ) {
    const scopeToRemove =
      values.includes('Participants') &&
      selectedScopes.includes('ParticipantsEdit')
        ? 'ParticipantsEdit'
        : values.includes('ParticipantsEdit') &&
            selectedScopes.includes('Participants')
          ? 'Participants'
          : ''

    setSelectedScopes(values.filter((scope) => scope !== scopeToRemove))
  } else {
    setSelectedScopes(values)
  }
}

////////////

const MultiSelectWrapper = styled.div`
  & > div > div:first-child {
    display: grid;
  }

  & > div > div:first-child > span {
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-all;
    -webkit-line-clamp: 1;
  }
`
