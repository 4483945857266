import { useState } from 'react'

import {
  CategoryMap,
  CategoryTreeMap,
  SelectedCategoryIdsBySet,
} from '../types'

type Params = {
  categoryMap: CategoryMap
  categoryTreeMap: CategoryTreeMap
  initialSelection?: string[]
}

export const useCategorySelectionManager = ({
  categoryMap,
  categoryTreeMap,
}: Params) => {
  const [selection, setSelection] = useState<CategoryMap>({})

  const updateSelectionByInitialSelection = (
    initialSelection: string[]
  ): CategoryMap => {
    if (initialSelection && initialSelection.length > 0) {
      const newSelection = initialSelection.reduce(
        (acc, id) => ({ ...acc, [id]: categoryMap[id] }),
        selection
      )
      setSelection(newSelection)
      return newSelection
    }

    setSelection({})
    return {}
  }

  const updateSelectionByUrlSelection = (
    urlSelection: string[] | null
  ): CategoryMap => {
    if (urlSelection) {
      const newSelection = Object.fromEntries(
        Object.entries(categoryMap).filter(([_, { name }]) =>
          urlSelection.includes(name)
        )
      )
      setSelection(newSelection)
      return newSelection
    }

    setSelection({})
    return {}
  }

  const getCategoryByName = (categoryNames: string[]) =>
    Object.values(categoryMap).map(({ name }) => categoryNames.includes(name))

  const getSelectionBySetId = (selection: CategoryMap) => {
    const result: SelectedCategoryIdsBySet = {}

    Object.entries(selection).forEach(([categoryId, category]) => {
      const { setId } = category

      if (!result[setId]) {
        result[setId] = []
      }

      result[setId].push({
        categoryId: categoryId,
        searchPath: category.searchPath,
      })
    })

    return result
  }

  const checkCategorySelection = (
    categoryId: string,
    selection: CategoryMap
  ): boolean => {
    const { parentId } = categoryMap[categoryId]

    return (
      !!selection[categoryId] ||
      (parentId && checkCategorySelection(parentId, selection))
    )
  }

  const togglePlainCategory = (
    categoryId: string,
    newSelection: CategoryMap
  ) => {
    let modifiedSelection = { ...newSelection }

    const isCategorySelected = checkCategorySelection(
      categoryId,
      modifiedSelection
    )

    // Check if current category selected
    if (isCategorySelected) {
      const { parentId } = categoryMap[categoryId]

      // Check if parent category exists and selected
      if (parentId && checkCategorySelection(parentId, modifiedSelection)) {
        const children = categoryTreeMap[parentId] || []

        // Select all children categories for current parent
        children.forEach((childId) => {
          modifiedSelection[childId] = categoryMap[childId]
        })

        // Unselect current category
        delete modifiedSelection[categoryId]

        // Unselect parent category
        modifiedSelection = togglePlainCategory(parentId, modifiedSelection)
      } else {
        // Unselect current category
        delete modifiedSelection[categoryId]
      }
    } else {
      // Select current category
      modifiedSelection[categoryId] = categoryMap[categoryId]

      const childrenIds = getChildrenIds(categoryId, categoryTreeMap)

      // Unselect all children categories
      childrenIds.forEach((id) => {
        delete modifiedSelection[id]
      })

      const { parentId } = categoryMap[categoryId]

      if (parentId) {
        const children = categoryTreeMap[parentId] || []
        const isAllChildrenSelected = children.every(
          (childId) => modifiedSelection[childId]
        )

        if (isAllChildrenSelected) {
          // Select parent category
          modifiedSelection = togglePlainCategory(parentId, modifiedSelection)
        }
      }
    }

    return modifiedSelection
  }

  const toggleCategory = (categoryId: string) => {
    const newSelection = togglePlainCategory(categoryId, { ...selection })

    setSelection(newSelection)

    return newSelection
  }

  return {
    getCategoryByName,
    getSelectionBySetId,
    selection,
    toggleCategory,
    updateSelectionByInitialSelection,
    updateSelectionByUrlSelection,
  }
}

const getChildrenIds = (
  categoryId: string,
  categoryTreeMap: CategoryTreeMap
) => {
  const childrenIds: string[] = []

  const addChildren = (id: string) => {
    const currentChildrenIds = categoryTreeMap[id] || []
    childrenIds.push(...currentChildrenIds)
    currentChildrenIds.forEach((childId) => addChildren(childId))
  }

  addChildren(categoryId)

  return childrenIds
}
