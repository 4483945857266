import React, { useState } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'
import { TimeInputControl } from '@/components/TimeControls'
import { DatePickerControl } from '@/components/TimeControls/'
import { useTheme } from '@/theme'

export const ParticipantCheckTime = ({
  checkTimeValue,
  setCheckTime,
  roomReservationId,
  readOnly,
}: any) => {
  const { spacing } = useTheme()

  const [editCheckTime, setEditCheckTime] = useState(false)

  return (
    <>
      <CheckTimeContainer>
        {readOnly ? (
          <ValuePlaceholder>
            {moment(checkTimeValue.date).format('DD.MM.YYYY')}
          </ValuePlaceholder>
        ) : (
          <DatePickerControl
            render={({ date, ref, toggleModal }: any) => (
              <div ref={ref}>
                <StyledButton onClick={toggleModal}>
                  {moment(date).format('DD.MM.YYYY')}
                </StyledButton>
              </div>
            )}
            setValue={(date) => {
              const specifiedDate = moment(
                `${date?.format('YYYY-MM-DD')} ${checkTimeValue.time.hour}:${
                  checkTimeValue.time.minute
                }:00`
              )
              setCheckTime(roomReservationId, specifiedDate)
            }}
            value={checkTimeValue.date}
          />
        )}
        {readOnly ? (
          <ValuePlaceholder>
            {`${checkTimeValue.time.hour}:${checkTimeValue.time.minute}`}
          </ValuePlaceholder>
        ) : editCheckTime ? (
          <TimeInputControl
            setValue={(value: any) => {
              setEditCheckTime(false)
              const specifiedDate = moment(
                `${checkTimeValue.date.format('YYYY-MM-DD')} ${value.hour}:${
                  value.minute
                }:00`
              )
              setCheckTime(roomReservationId, specifiedDate)
            }}
            value={
              checkTimeValue.time && {
                hour: checkTimeValue.time.hour,
                minute: checkTimeValue.time.minute,
              }
            }
          >
            {({ handleSubmit, inputValue, setInputValue }) => (
              <Input
                autoFocus
                onBlur={handleSubmit}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setInputValue(event.target.value)
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    handleSubmit()
                  }
                }}
                placeholder="—"
                style={{ height: `${spacing.gu(3)}rem`, width: 56 }}
                value={inputValue}
              />
            )}
          </TimeInputControl>
        ) : (
          <StyledButton onClick={() => setEditCheckTime(true)}>
            {`${checkTimeValue.time.hour}:${checkTimeValue.time.minute}`}
          </StyledButton>
        )}
      </CheckTimeContainer>
    </>
  )
}

///////

const CheckTimeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButton = styled.button<{ forcedWidth?: string }>`
  border: 0px;
  border-radius: 0px;
  width: ${(props) => props.forcedWidth || '100%'};
  background: transparent;
  cursor: pointer;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(0.5)}rem !important;
    height: ${theme.spacing.gu(3)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`

const ValuePlaceholder = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(0.5)}rem;
  `}
`
