import { useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { CategorySet } from '@/modules/Registry/Category'
import { generateCompareFn } from '@/utils/arrays'

import { CategorySetTree, CollapsedCategorySelectorValue } from './components'
import { useCategorySelectionManager } from './hooks'
import { CategoryMap, SelectedCategoryIdsBySet } from './types'
import { flattenCategorySets, getEmphasisedPaths, getQuery } from './utils'

type CategorySetChildren = CategorySet['rootCategories'][0]['children']

type Props = {
  categorySets: CategorySet[]
  defaultSort?: {
    [key: string]: string
  }
  documentCounts: {
    [path: string]: number
  }
  initialSelection?: string[]
  hideEmpty?: boolean
  isCollapsed?: boolean
  onUpdateSelection?: (arg0: SelectedCategoryIdsBySet) => void
  setQuery: (...args: Array<any>) => any
  urlSelection?: string[] | null
}

export const CategorySelector = ({
  categorySets,
  defaultSort,
  documentCounts,
  initialSelection,
  hideEmpty,
  isCollapsed,
  onUpdateSelection,
  setQuery,
  urlSelection,
}: Props) => {
  // Transform category data into flat structures for easier manipulation
  const { categoryMap, categoryTreeMap } = useMemo(
    () => flattenCategorySets(categorySets),
    [categorySets]
  )

  const {
    selection,
    getSelectionBySetId,
    toggleCategory,
    updateSelectionByInitialSelection,
    updateSelectionByUrlSelection,
  } = useCategorySelectionManager({
    categoryMap,
    categoryTreeMap,
  })

  const updateSelection = (newSelection: CategoryMap) =>
    onUpdateSelection
      ? onUpdateSelection(getSelectionBySetId(newSelection))
      : setQuery(getQuery(newSelection, defaultSort))

  useEffect(() => {
    // Update selection from initial selection
    if (initialSelection?.length) {
      const selection = updateSelectionByInitialSelection(initialSelection)
      updateSelection(selection)
    }
  }, [JSON.stringify(initialSelection)])

  useEffect(() => {
    // Update selection from URL params
    if (!initialSelection?.length) {
      const selection = updateSelectionByUrlSelection(urlSelection ?? null)
      updateSelection(selection)
    }
  }, [JSON.stringify(urlSelection)])

  const handleToggleCategory = (id: string) =>
    updateSelection(toggleCategory(id))

  if (isCollapsed) {
    return (
      <CollapsedCategorySelectorValue
        selection={selection}
        categorySets={categorySets}
        placeholder={<T>ResourceReservationsCalendar:CategorySelector.empty</T>}
      />
    )
  }

  const sortData = (data: CategorySetChildren): CategorySetChildren =>
    [...data].sort(generateCompareFn('name')).map((item) =>
      item.children
        ? {
            ...item,
            children: sortData(item.children as CategorySetChildren),
          }
        : item
    )

  const categorySetsSorted = [...categorySets]
    .sort(generateCompareFn('name'))
    .map((set) => ({
      ...set,
      rootCategories: set.rootCategories
        .sort(generateCompareFn('name'))
        .map((category) => ({
          ...category,
          children: sortData(category.children),
        })),
    }))

  return (
    <Wrapper>
      {categorySetsSorted.map((x) => {
        const emphasisedPaths = getEmphasisedPaths(x)

        return (
          <CategorySetTree
            data={x}
            documentCounts={documentCounts}
            emphasisedPaths={emphasisedPaths}
            expand={
              categorySets.length === 1 || emphasisedPaths.includes('root')
            }
            hideEmpty={hideEmpty}
            key={`category-set-tree-${x.id}`}
            selection={selection}
            toggleCategory={handleToggleCategory}
          />
        )
      })}
    </Wrapper>
  )
}

////////////

const Wrapper = styled.div`
  border-radius: 4px;
  max-height: 500px;
  overflow: auto;

  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
