import { RawDraftContentState } from 'draft-js'
import escape from 'lodash.escape'
import { draftToMarkdown } from 'markdown-draft-js'

type DraftLinkData = {
  data: {
    url: string
  }
}

export const convertDraftToPDFString = (draft: RawDraftContentState) => {
  // Escape raw text before conversion
  const escapedDraft = {
    ...draft,
    blocks: draft.blocks.map((block) => ({
      ...block,
      text: escapeTextForPDF(block.text), // Escape only content text
    })),
  }
  const markdownString = draftToMarkdown(escapedDraft, {
    entityItems: {
      LINK: {
        close: () => '</Link>',
        open: (data) => `<Link src="${(data as DraftLinkData).data.url}">`,
      },
    },
    styleItems: {
      BOLD: {
        close: () => '</Text>',
        open: () => '<Text style={{ fontWeight: 700 }}>',
      },
      ITALIC: {
        close: () => '</Text>',
        open: () => '<Text style={{ fontStyle: "italic" }}>',
      },
    },
  })

  const newLineReplacer = (match: string) =>
    match ? `<Text style={{ marginBottom: 2 }}>${match}</Text>` : ''

  return markdownString.replace(/(.*\S.*)/gm, newLineReplacer)
}

const escapeTextForPDF = (text: string) =>
  escape(text).replace(/{/g, '&#123;').replace(/}/g, '&#125;')
