import { ChangeEvent, KeyboardEvent, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import moment, { Moment } from 'moment'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components'

import { Input } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import {
  DateRange,
  DateRangePicker,
  TimeValue,
} from '@/components/TimeControls'
import { TimeInputControl } from '@/components/TimeControls'
import { T } from '@/modules/Language'
import { CheckBox } from '@/modules/ParticipantsList/components/common'
import { participantQueries } from '@/modules/ParticipantsList/queries'
import { useTheme } from '@/theme'

import {
  ParticipantDefaultVstDatesQuery,
  ParticipantDefaultVstDatesQueryVariables,
} from '~generated-types'

type Dates = {
  end: Moment
  start: Moment
}

type Props = {
  dates: Dates | null
  isDefault: boolean
  isTimeError: boolean
  isUpdateAll: boolean
  participantId: string
  setDates: (dates: Dates | null) => void
  setDefault: (isDefault: boolean) => void
  setTimeError: (isTimeError: boolean) => void
  setUpdateAll: (updateAll: boolean) => void
}

export const VSTDatesModal = ({
  dates,
  isDefault,
  isTimeError,
  isUpdateAll,
  participantId,
  setDates,
  setDefault,
  setTimeError,
  setUpdateAll,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { data, loading } = useQuery<
    ParticipantDefaultVstDatesQuery,
    ParticipantDefaultVstDatesQueryVariables
  >(participantQueries.PARTICIPANT_DEFAULT_VST_DATES, {
    fetchPolicy: 'no-cache',
    variables: { id: participantId },
  })

  const defaultDatesData = data?.salesParticipant?.vst?.defaults || null
  const defaultDates = defaultDatesData
    ? {
        end: moment(defaultDatesData.end),
        start: moment(defaultDatesData.start),
      }
    : null

  useEffect(() => {
    if (isDefault) {
      setDates(defaultDates)
    }
  }, [JSON.stringify(defaultDates), isDefault])

  useEffect(() => {
    if (dates && defaultDates) {
      const isStartSame = dates.start.isSame(defaultDates.start)
      const isEndSame = dates.end.isSame(defaultDates.end)

      setDefault(isStartSame && isEndSame)
    } else if (dates && !defaultDates) {
      setDefault(false)
    } else if (!dates && defaultDates) {
      setDefault(true)
    }
  }, [JSON.stringify(dates), JSON.stringify(defaultDates)])

  useEffect(() => {
    if (dates) {
      const isStartAndEndSame = dates.start.isSame(dates.end, 'day')
      const isStartAfterEnd = dates.start.isSameOrAfter(dates.end)

      setTimeError(isStartAndEndSame && isStartAfterEnd)
    }
  }, [JSON.stringify(dates)])

  const setDatesValue = (value: DateRange | null | undefined) => {
    if (!value) {
      return setDates(null)
    }

    const { to, from } = value

    const range = {
      end: to.set({
        hour: dates?.end?.hour() || 12,
        minute: dates?.end?.minute() || 0,
      }),
      start: from.set({
        hour: dates?.start?.hour() || 15,
        minute: dates?.start?.minute() || 0,
      }),
    }

    return setDates(range)
  }

  const setStartTimeValue = (value: TimeValue | null | undefined) => {
    if (!value || !dates) {
      return
    }

    return setDates({
      end: dates.end,
      start: dates.start
        .clone()
        .set('hour', value.hour)
        .set('minute', value.minute),
    })
  }

  const setEndTimeValue = (value: TimeValue | null | undefined) => {
    if (!value || !dates) {
      return
    }

    return setDates({
      end: dates.end
        .clone()
        .set('hour', value.hour)
        .set('minute', value.minute),
      start: dates.start,
    })
  }

  return (
    <FlexRow>
      <SectionWrapper>
        {loading || (isDefault && !dates && defaultDates) ? (
          <LoaderWrapper>
            <ReactLoading
              color={palette.smoke.extraDark}
              type={'spin'}
              height={24}
              width={24}
            />
          </LoaderWrapper>
        ) : (
          <DateRangePicker
            setValue={setDatesValue}
            value={dates && { from: dates.start, to: dates.end }}
          />
        )}

        <HorizontalDivider />

        <TimeWrapper>
          <FlexRow
            flex={1}
            alignItems="center"
            justifyContent="space-between"
            style={{
              marginBottom: `${spacing.gu(1)}rem`,
              width: '100%',
            }}
          >
            <Label>
              <T>ParticipantsList:VST.startTime</T>:
            </Label>
            <TimeInputControl
              setValue={setStartTimeValue}
              value={
                dates && {
                  hour: Number(dates.start.format('HH')),
                  minute: Number(dates.start.format('mm')),
                }
              }
            >
              {({ handleSubmit, inputValue, setInputValue }) => (
                <TimeInput
                  disabled={!dates}
                  isError={isTimeError}
                  onBlur={handleSubmit}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setInputValue(e.target.value)
                  }
                  onKeyDown={(e: KeyboardEvent) => {
                    if (e.key === 'Enter') {
                      handleSubmit()
                    }
                  }}
                  placeholder="__ : __"
                  value={inputValue}
                />
              )}
            </TimeInputControl>
          </FlexRow>
          <FlexRow
            flex={1}
            alignItems="center"
            justifyContent="space-between"
            style={{ width: '100%' }}
          >
            <Label>
              <T>ParticipantsList:VST.endTime</T>:
            </Label>
            <TimeInputControl
              setValue={setEndTimeValue}
              value={
                dates && {
                  hour: Number(dates.end.format('HH')),
                  minute: Number(dates.end.format('mm')),
                }
              }
            >
              {({ handleSubmit, inputValue, setInputValue }) => (
                <TimeInput
                  isError={isTimeError}
                  disabled={!dates}
                  onBlur={handleSubmit}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setInputValue(e.target.value)
                  }
                  onKeyDown={(e: KeyboardEvent) => {
                    if (e.key === 'Enter') {
                      handleSubmit()
                    }
                  }}
                  placeholder="__ : __"
                  value={inputValue}
                />
              )}
            </TimeInputControl>
          </FlexRow>
        </TimeWrapper>
      </SectionWrapper>

      <VerticalDivider />

      <SectionWrapper>
        <Gutter type={[2, 0, 1]}>
          <FlexRow alignItems="center">
            <CheckBox
              isDisabled={!defaultDates}
              isSelected={isDefault}
              onClick={() => setDefault(!isDefault)}
            />
            <T>ParticipantsList:VST.setDefaultDates</T>
          </FlexRow>
          <DescriptionWrapper>
            <p>
              <T>ParticipantsList:VST.defaultDatesDescription.part1</T>
            </p>
            <p>
              <T>ParticipantsList:VST.defaultDatesDescription.part2</T>
            </p>
            <p>
              <T>ParticipantsList:VST.defaultDatesDescription.part3</T>
            </p>
          </DescriptionWrapper>
        </Gutter>

        <Gutter type={[1, 0, 0]}>
          <FlexRow alignItems="center">
            <CheckBox
              isSelected={isUpdateAll}
              onClick={() => setUpdateAll(!isUpdateAll)}
            />
            <T>ParticipantsList:VST.applyForAll</T>
          </FlexRow>
          <DescriptionWrapper>
            <p>
              <T>ParticipantsList:VST.applyForAllDescription.part1</T>
            </p>
            <p>
              <T>ParticipantsList:VST.applyForAllDescription.part2</T>
            </p>
          </DescriptionWrapper>
        </Gutter>
      </SectionWrapper>
    </FlexRow>
  )
}

const Label = styled.span`
  font-weight: 500;

  margin-right: ${({ theme }) => theme.spacing.gu(1)}rem;
`

const TimeInput = styled(Input)<{ isError: boolean }>`
  &:not([type='checkbox']):not([type='radio']) {
    height: 24px;
    max-width: 54px;
    text-align: center;

    ${({ isError, theme }) => css`
      border-color: ${isError
        ? theme.palette.danger.main
        : theme.palette.smoke.dark};
    `}
  }
`

const TimeWrapper = styled(FlexColumn)`
  flex: 1;
  width: 100%;
  align-items: flex-start;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}
`

const SectionWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
  `}
`

const HorizontalDivider = styled.div<{ margin?: string }>`
  width: 100%;

  ${({ theme, margin }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
    margin: ${margin ? margin : `${theme.spacing.gu(1)}rem 0`};
  `}
`

const VerticalDivider = styled.div`
  align-self: stretch;

  ${({ theme }) => css`
    border-right: 1px solid ${theme.palette.smoke.main};
    margin: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(1)}rem 0;
  `}
`

const DescriptionWrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(2)}rem 0 calc(20px + ${theme.spacing.gu(2)}rem);

    & > p {
      color: ${theme.palette.smoke.extraDark};
    }
  `}

  max-width: 240px;
`

const LoaderWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
`
